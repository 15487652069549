
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";

@Component({})
export default class SignIn extends mixins(Navigation) {
  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }
}
